<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>学员名称/手机号</span>
              </template>
              <a-input v-model.trim="searchData.fuzzy" placeholder="学员名称/手机号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>签约合同号</span>
              </template>
              <a-input v-model.trim="searchData.contract_no" placeholder="签约合同号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>签约校区</span>
              </template>
              <a-select v-model="searchData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' placeholder="请选择签约校区" style="width: 220px">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>课程顾问</span>
              </template>
              <a-select v-model="searchData.course_consultant_id" placeholder="请选择课程顾问" style="width: 160px">
                <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" :loading="loading" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="contract_id"
        :columns="columns" :dataSource="list" :scroll="{ x: 1500 }">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="action" slot-scope="text, record">
          <a href="javascript:;">退款</a>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width: '100px', dataIndex: 'name', key: 'name', fixed: 'left' },
  { title: '学员手机号', width: '100px', dataIndex: 'age', key: 'age'},
  { title: '签约合同号', dataIndex: 'address', key: '1' },
  { title: '可用校区', dataIndex: 'address', key: '2' },
  { title: '课程顾问', dataIndex: 'address', key: '3' },
  { title: '课时/按期', dataIndex: 'address', key: '4' },
  { title: '课程花费', dataIndex: 'address', key: '5' },
  { title: '签约课时费', dataIndex: 'address', key: '6' },
  { title: '权益', dataIndex: 'address', key: '7' },
  { title: '可退课时', dataIndex: 'address', key: '8' },
  { title: '添加时间', dataIndex: 'address', key: '9' },
  { title: '操作', key: 'operation', fixed: 'right', width: 46, scopedSlots: { customRender: 'action' }}
]

import C_ITEMS from '@/utils/items'	
export default {
  name: 'skjl',
  rovide() {
    return {
      parent: this
    }
  },

  components: {
    // refundModal
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      paymentStatus: C_ITEMS.paymentStatus,
      studios: [],
      consultants: [],

      searchData: {},

      visible: false,
      refundVisible: false,
    }
  },

  created() {
    this.pageParams.perPage = this.$ls.get('perPage') || 10
    this.searchParams['per-page'] = this.pageParams.perPage
    // this.getList()
    this.getStudio()
    this.getConsultant()
  },

  methods: {
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchStudioAction', {})
      this.studios = res.data
    },
    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.consultants = res.data
    },

    async getList() {
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'class_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('financeRefundAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    changePage(page, pageSize) {
      this.searchParams.page = page
      this.getList()
    },
    onShowSizeChange(current, size) {
      this.searchParams['per-page'] = size
      this.$ls.set('perPage',size)
      this.getList()
    },
    reset() {
      this.searchData = {}
    },
    searchList(e) {
      e?e.preventDefault():''
      this.searchParams.page = 1
      this.getList()
    },

    showRefundModal(item) {
      this.modalData = item
      this.refundVisible = true
    },
    hideRefundModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.refundVisible = false
    },
  }
}
</script>