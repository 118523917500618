<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>财务中心</a-breadcrumb-item>
      <a-breadcrumb-item>退费记录</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <!-- <a-tab-pane key="1">
          <span slot="tab">退费办理</span>
          <tfbl v-if="activeKey === '1'"/>
        </a-tab-pane> -->
        <a-tab-pane key="1">
          <span slot="tab">退费记录</span>
          <tfjl v-if="activeKey === '1'"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import tfbl from './tfbl'
import tfjl from './tfjl'

export default {
  name: 'Refund',

  components: {
    tfbl,
    tfjl
  },

  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>
