<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canExcelexport">
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-range-picker style="width:240px" :ranges="rangesData" :disabled-date="disabledDate" allowClear v-model="searchData.trade_date" :placeholder="['开始时间', '结束时间']"/>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>学员名称/手机号/退款单号/交易单号</span>
              </template>
              <a-input v-model.trim="searchData.user_search" allowClear placeholder="学员/手机/支付内容/订单号" style="width: 210px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-select style="width:200px" allowClear showSearch v-model="searchData.studio_id" placeholder="交易校区" :filterOption="filterOption">
              <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" :loading="loading" @click="searchList">搜索</a-button>
          </a-form-item>
          <!-- <a-form-item>
            <a-popover placement="bottom" trigger="click" v-model="visible">
              <template slot="content">
                <div class="more-search">
                  <a-form layout='inline'>
                    <a-form-item class="block-line" label="来源渠道" >
                        <a-tree-select
                            v-model="searchData.channel_id"
                            :tree-data="parentChannels"
                            tree-checkable
                            allowClear
                            treeNodeFilterProp="title"
                            :maxTagCount='1'
                            :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                            :show-checked-strategy="SHOW_ALL"
                            placeholder="请选择来源渠道"
                        />
                    </a-form-item>
                    <a-form-item class="block-line" label="支付方式" >
                      <a-select v-model="searchData.trade_type" allowClear placeholder="请选择">
                        <a-select-option v-for="(d, index) of paymentTypes" :key="index" :value="d['payment_type']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="支付类型" >
                      <a-select v-model="searchData.type" allowClear placeholder="请选择">
                        <a-select-option v-for="(d, index) of tradeTypes" :key="index" :value="d['trade_type']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="支付状态" >
                      <a-select v-model="searchData.trade_status" allowClear placeholder="请选择">
                        <a-select-option v-for="(d, index) of paymentStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-form>
                  <div class="more-search__buttons">
                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                    <a-button @click="reset">重置</a-button>
                    <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                  </div>
                </div>
              </template>
              <a-button>更多<a-icon type="down" /></a-button>
            </a-popover>
          </a-form-item> -->
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="refund_id"
        :columns="columns" :dataSource="list" :scroll="{ x: 100 }">
        <template slot="index" slot-scope="text, record , index">
          <span v-if="index+1 == list.length">合计</span>
          <span v-else>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>

         <template slot="studentName" slot-scope="text, record, index">
            <span v-if="index+1 == list.length"></span>
            <LUser v-else :data="record"/>
          </template>

        <template slot="action" slot-scope="text, record">
          <a href="javascript:;">退款</a>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'refund_id', fixed: 'left'},
  { title: '学员名称', width:'200px', dataIndex: 'student_name', key: '1' , scopedSlots: { customRender: 'studentName' }, fixed: 'left' },
  { title: '退款单号', width:'180px', dataIndex: 'serial_no', key: 'serial_no',ellipsis: true},
  { title: '退款校区', dataIndex: 'studio_name', key: 'studio_name',ellipsis: true},
  { title: '退款科目', dataIndex: 'course_cate_id', key: 'course_cate_id',ellipsis: true},
  { title: '退课数', width:'80px', dataIndex: 'refund_times', key: '4'},
  { title: '退费金额', width:'100px', dataIndex: 'refund_total', key: 'refund_total',align:'center'},
  { title: '扣费金额', width:'100px', dataIndex: 'refund_reduce', key: 'refund_reduce',align:'center'},
  { title: '实退金额', width:'100px', dataIndex: 'refund_actual_amount', key: 'refund_actual_amount',align:'center'},
  { title: '签单顾问', width:'80px', dataIndex: 'course_consultant_id', key: 'course_consultant_id' },
  { title: '经办人', width:'80px', dataIndex: 'created_by', key: '8' },
  { title: '退款方式', width:'80px', dataIndex: 'refund_type', key: '9' },
  { title: '退费备注', dataIndex: 'refund_remark', key: '10' ,ellipsis: true},
  { title: '退款时间', dataIndex: 'created_at', key: '11' }
]

import C_ITEMS from '@/utils/items'	
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
export default {
  name: 'skjl',
  rovide() {
    return {
      parent: this
    }
  },

  components: {
    // refundModal
  },

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      paymentStatus: C_ITEMS.paymentStatus,
      studios: [],
      tradeTypes: [],
      consultants: [],
      parentChannels:[],
      searchData: {
        trade_date:[moment(this.$route.query.start_date),moment(this.$route.query.end_date)]
      },
      paymentTypes: [],
      visible: false,
      refundVisible: false,
      authType:['finance','refund'],
    }
  },
  mixins: [ authority , tableMixins, ranges ],
  created() {
    if(this.$route.query.start_date){
      // this.searchData.
    }
    this.getStudio()
    this.getTradeType()
    this.getConsultant()
    this.getPaymentType()
    this.getSourceChannel()
  },

  methods: {
    filterOption(input, option) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.consultants = res.items
    },
    async getPaymentType() {
      let res = await this.$store.dispatch('searchPaymentTypeAction', {})
      this.paymentTypes = res.data
    },
    async getTradeType() {
      let res = await this.$store.dispatch('searchTradeTypeAction', {})
      this.tradeTypes = res.data
    },
    async getSourceChannel(parent_id) {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'trade_date') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('financeRefundListAction', { data: this.searchParams })
      this.list = res.items
      this.list.push({
        refund_id:-1,
        student_name:'',
        student_cellphone:'',
        serial_no:'',
        studio_name:'',
        course_cate_id:'',
        refund_times:res._total.refund_times,
        refund_total:res._total.refund_total,
        refund_reduce:res._total.refund_reduce,
        refund_actual_amount:res._total.refund_actual_amount,
        course_consultant_id:'',
        created_by:'',
        refund_type:'',
        refund_remark:'',
        created_at:''
      })
      this.pageParams = res._meta
      this.loading = false
    },

    showRefundModal(item) {
      this.modalData = item
      this.refundVisible = true
    },
    hideRefundModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.refundVisible = false
    },
    async toExport(){
      this.exportLoading = true
      let res = await this.$store.dispatch('financeRefundExportAction', { data: {search:this.searchParams.search} })
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `退费记录.${moment().format('YYYY.MM.DD')}.xlsx`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    }
  }
}
</script>
